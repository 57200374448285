<template>
  <!-- eslint-disable vue/max-attributes-per-line -->
  <validation-observer ref="simpleRules">
    <b-form @submit.prevent>
      <b-row>
        <b-col md="9">
          <Fieldset id="information" :title="$t('Basic Information')">
            <b-form-group :label="`*` + $t('Name') + `:`" label-cols-md="12">
              <validation-provider
                #default="{ errors }"
                name="Name"
                rules="required"
              >
                <b-form-input
                  v-model="model.name"
                  :placeholder="$t('Name')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </Fieldset>
        </b-col>
        <b-col md="3">
          <div class="position-sticky top">
            <Fieldset id="status" :title="$t('Status')">
              <b-form-group :label="$t('Display on/off')+`:`" label-cols-md="6">
                <b-form-checkbox
                  v-model="model.is_active"
                  checked="true"
                  class="custom-control-success"
                  name="check-button"
                  switch
                />
              </b-form-group>
              <b-form-group label="Origin:" label-cols-md="6">
                <div v-if="model.origin" class="text-success d-flex align-items-center h-100">
                  Yes
                </div>
                <div v-else class="text-danger d-flex align-items-center h-100">
                  No
                </div>
              </b-form-group>
              <hr>
              <!-- Submit -->
              <div class="text-right">
                <b-button
                  variant="primary"
                  type="submit"
                  class="text-right"
                  @click.prevent="validationForm"
                >
                  {{ $t('Update') }}
                </b-button>
              </div>
            </Fieldset>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
/* eslint-disable operator-linebreak */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable prefer-destructuring */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BForm,
  BFormCheckbox,
} from 'bootstrap-vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  data() {
    return {
      required,
      model: {},
    }
  },
  mounted() {
    this.loadDetail()
  },
  methods: {
    async loadDetail() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/domain/${this.$route.params.id}?site_id=${siteId}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.model = res.data.data
            this.model.is_active = Boolean(res.data.data.is_active === 1 ? true : false)
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async validationForm() {
      if (this.model.origin === 0) {
        this.$refs.simpleRules.validate().then(async success => {
          if (success) {
            try {
              const params = {
                site_id: JSON.parse(localStorage.getItem('siteID')).id,
                name: this.model.name,
                is_active: Boolean(this.model.is_active === true ? 1 : 0),
              }
              const res = await Request.put(
                this.$http,
                `${process.env.VUE_APP_API_URL}/domain/${this.$route.params.id}`,
                params,
              )
              if (res.status === 200) {
                if (res.data.status) {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Congratulation !',
                      icon: 'CheckCircleIcon',
                      variant: 'success',
                      text: 'Update Site success',
                    },
                  })
                  this.$router.push('/domain/list')
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Opps! Something wrong',
                      icon: 'AlertOctagonIcon',
                      variant: 'danger',
                      text: String(this.showError(res.data.error, ',')),
                    },
                  })
                }
              }
            } catch (error) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Opps! Something wrong',
                  icon: 'AlertOctagonIcon',
                  variant: 'danger',
                  text: String(error),
                },
              })
            }
          }
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: 'Cannot edit origin domain',
          },
        })
      }
    },
  },
}
</script>
